<div class="py-5 hide-for-print" id="contributions">
  <div class="w-100">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-12 pl-lg-5 offset-sm-0 offset-md-5 text-center">
          <h3>{{ 'form.contribution.title' | transloco }}</h3>
        </div>
      </div>

      <form [ngrxFormState]="contributionForm$ | async" class="my-5" *transloco="let t; read: 'form.contribution'">
        <h4>{{ t('financialAid.title') }}</h4>
        <p class="text-muted helper text mb-5">{{ t('financialAid.desc') }}</p>

        <app-form-input class="d-block mb-5" [formControlState]="(contributionForm$ | async).controls.scholarship"
          [isCurrency]="true" translationScope="form.contribution.scholarship">
        </app-form-input>
        <app-form-input class="d-block mb-5" [formControlState]="(contributionForm$ | async).controls.grant"
          [isCurrency]="true" translationScope="form.contribution.grant">
        </app-form-input>
        <app-form-input class="d-block mb-5" [formControlState]="(contributionForm$ | async).controls.other"
          [isCurrency]="true" translationScope="form.contribution.other">
        </app-form-input>
        <app-form-input class="d-block mb-5" [formControlState]="(contributionForm$ | async).controls.savings"
          [isCurrency]="true" translationScope="form.contribution.savings">
        </app-form-input>
        <div>
          <label>{{ t('income.title') }}</label>
          <p><small class="text-muted helper text">
              {{ t('income.desc') }}
            </small></p>
          <app-dynamic-input-form
            [formState]="(contributionForm$ | async).controls.income.controls"
            [addField]="addContribution"
            [removeField]="removeContribution"
            [analyticsAddMore]="'tdct:p:student:budget:calculator:additional:expenses:financial:aid'"
            [notEmpty]="true"
          ></app-dynamic-input-form>
        </div>
      </form>
      <div class="row">
        <div class="col-12 col-sm-8 pl-lg-5 offset-sm-0 offset-md-7 text-center next-btn-wrapper mt-5">
          <button class="btn btn-block td-btn-primary-light"
            [disabled]="!canAdvance" (click)="advanceFormState()"
            data-analytics-click="tdct:p:student:budget:calculator:next:step">
            {{ 'form.btn.result' | transloco }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>