import { Component, Input, OnInit } from '@angular/core';
import { FormControlState } from 'ngrx-forms';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent implements OnInit {
  @Input() formControlState: FormControlState<any>;
  @Input() translationScope: string;
  @Input() isCurrency: boolean;
  @Input() hasSuffix?: boolean;
  @Input() isDynamic?: boolean;
  @Input() dynamicLabel?: any;
  @Input() dynamicFallback?: string;

  activeLang: string;
  constructor(private translocoService: TranslocoService) {
    this.activeLang = translocoService.getActiveLang();
  }

  inputAriaLabel = () => {
    const t = (fieldName) => {
      return this.translocoService.translate(
        this.isDynamic ? fieldName : `${this.translationScope}.${fieldName}`
      );
    };

    
    if (this.isDynamic && this.dynamicFallback) {
      if (this.dynamicLabel?.value) {
        return this.dynamicLabel.value;
      } else {
        return t(`${this.dynamicFallback}.placeholder`);
      }
    } else if ((!(t('title') === '') || !(t('desc') === '')) && !this.isDynamic) {
      return t('title') || t('desc');
    }
  };

  ngOnInit(): void {
    if (this.isDynamic) {
      this.dynamicLabel = this.formControlState.value;
      this.dynamicFallback = this.dynamicFallback;
    }
  }

  toArray(errors: object): string[] {
    return Object.keys(errors);
  }
}
