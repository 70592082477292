import { HttpClient } from '@angular/common/http';

import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) { }

  getTranslation(lang: string) {
    let buildURL = '/'
    if (window.location) {
      let lastIndex = window.location.href.lastIndexOf('/#/')
      buildURL = window.location.href.substring(0, lastIndex)
    }
    return this.http.get<Translation>(`${buildURL}/assets/i18n/${lang}.json`);
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en', 'fr', 'zh', 'zt'],
        defaultLang: 'en',
        reRenderOnLangChange: false,
        prodMode: environment.production,
        missingHandler: {
          allowEmpty: true
        }
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
  ]
})
export class TranslocoRootModule { }
