<div class="col-12 form-group td-form-group-padding px-0 dynamic-form-wrapper"
  [ngClass]="{'not-empty': notEmpty}"
>
  <div class="row align-items-start" *ngFor="let control of formState;
               trackBy: trackByIndex;
               let i = index"
      [ngClass]="{
        'dynamic': control.controls.isDefault.value === false,
        'not-dynamic': control.controls.isDefault.value === true
      }"
  >
    <div class="col">
      <div class="row">
        <div class="col-5 col-sm-5 form-group td-form-group-padding">
          <div class="mt-2" *ngIf="control.controls.isDefault.value">
            <label
              [attr.id]="control.controls.value.id + '-label'"
              *ngIf="control.controls.isDefault.value"
            >
              {{control.controls.name.value | transloco}}
            </label>
            <div *ngIf="control.controls.desc.value">
              {{control.controls.desc.value | transloco}}
            </div>
          </div>
          <app-form-input
            *ngIf="!control.controls.isDefault.value"
            [isCurrency]="false"
            [formControlState]="control.controls.name"
            [translationScope]="control.controls.nameInputTranslationScope ? control.controls.nameInputTranslationScope.value : 'form.empty'">
          </app-form-input>
        </div>
        <div class="col-7 col-sm-7 form-group td-form-group-padding">
          <div class="row">
            <div class="col flex-grow-1">
              <app-form-input
                [isCurrency]="true"
                [formControlState]="control.controls.value"
                [translationScope]="control.controls.priceInputTranslationScope ? control.controls.priceInputTranslationScope.value : 'form.empty'"
                [hasSuffix]="control.controls.priceInputTranslationScope"
                [isDynamic]="true"
                [dynamicLabel]="control.controls.name"
                [dynamicFallback]="control.controls.nameInputTranslationScope ? control.controls.nameInputTranslationScope.value : ''"
              ></app-form-input>
            </div>
            <div class="col-auto pl-0 d-sm-block" [hidden]="control.controls.isDefault.value">
              <div class="remove-wrapper">
                <button [style.visibility]="control.controls.isDefault.value ? 'hidden' : 'visible'" class="remove-btn"
                  (click)="removeField(i)">
                  <span class="td-icon td-icon-close" aria-hidden="true"></span>
                  <span class="sr-only">{{ 'form.btn.delete' | transloco }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button
    style="width: 65%"
    type="button"
    class="btn mt-4 td-btn-secondary-clear"
    (click)="addField()"
    [attr.data-analytics-click]="analyticsAddMore"
    [id]="(formState[0].id).replace('.0', '') + '.addMore'"
  >
    {{'form.dynamicInputs.addAnother' | transloco }}
  </button>
</div>