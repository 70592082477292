import {ChangeDetectionStrategy, Component, Input, NgIterable} from '@angular/core';
import {FormControlState} from 'ngrx-forms';
import {Store} from '@ngrx/store';
import {AppState} from '../../store';

@Component({
    selector: 'app-dynamic-input-form',
    templateUrl: './dynamic-input-form.component.html',
    styleUrls: ['./dynamic-input-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicInputFormComponent {
    private appStore: Store<AppState>;
    @Input() addField: () => void;
    @Input() removeField: (index: number) => void;
    @Input() formState: FormControlState<any> & NgIterable<any>;
    @Input() analyticsAddMore: String;
    @Input() notEmpty: boolean;

    constructor(private store: Store<AppState>) {
        this.appStore = store;
        this.analyticsAddMore = this.analyticsAddMore || '';
        this.notEmpty = this.notEmpty || false;
    }

    trackByIndex(index: number): any {
        return index;
    }
}
