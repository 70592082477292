import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState, InvalidFieldsSelector, incrementActivePage, incrementValidatedToPage, setActivePage } from 'src/app/store';
import { Observable } from 'rxjs';
import { FormGroupState, AddArrayControlAction, RemoveArrayControlAction } from 'ngrx-forms';
import { Expenses, Global } from 'src/app/models';
import { TranslocoService } from '@ngneat/transloco';
import { formatCurrency,getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss']
})
export class ExpensesComponent implements OnInit {
  private appStore: Store<AppState>;
  global$: Observable<Global>;
  expensesForm$: Observable<FormGroupState<Expenses>>;
  appErrors$: Observable<number>;
  canAdvance: number;
  ValidatedToPage: number;
  groceryChoices = [25, 50, 100];
  entertainmentChoices = [25, 75, 150];
  activeLang: string;

  constructor(private store: Store<AppState>, private invalidFieldsSelector: InvalidFieldsSelector, private translocoService: TranslocoService) {
    this.activeLang = translocoService.getActiveLang();
    this.appStore = store;
    this.global$ = store.pipe(
      select(state => state.global as Global),
    );
    this.appErrors$ = invalidFieldsSelector.appErrors$;
    this.expensesForm$ = store.pipe(
      select(state => state.form.controls.expenses as FormGroupState<Expenses>)
    );

    const vm = this;
    this.global$.subscribe(async (globals) => {
      vm.ValidatedToPage = globals.ValidatedToPage
    });
  }

  addAdditionalExpense(): void {
    this.appStore.dispatch(
      new AddArrayControlAction(
        'form.expenses.additional.additionalCost',
        {
          name: '',
          value: '',
          isDefault: false,
          nameInputTranslationScope: 'form.costs.expenses.enterExpense'
        }
      ));

      let focusObj = 0;
      this.appStore.subscribe((state) => {
        focusObj = state.form.controls.expenses.controls.additional.controls.additionalCost.controls.length -1
      })

      let target = 'form.expenses.additional.additionalCost'
      window.setTimeout(() => {
        const element:HTMLElement = document.getElementById(`${target}.${focusObj}.name`) || document.getElementById(`${target}.${focusObj}.value`)
        element.focus();
      }, 0);
  }

  removeAdditionalExpense(index: number): void {
    const target = 'form.expenses.additional.additionalCost'

    this.appStore.dispatch(
      new RemoveArrayControlAction(
        target,
        index
      ));

    document.getElementById(`${target}.addMore`).focus()
  }

  addCommonExpense(): void {
    this.appStore.dispatch(
      new AddArrayControlAction(
        'form.expenses.common.commonCost',
        {
          name: '',
          value: '',
          isDefault: false,
          nameInputTranslationScope: 'form.costs.expenses.enterExpense'
        }
      ));

    let focusObj = 0;
    this.appStore.subscribe((state) => {
      focusObj = state.form.controls.expenses.controls.common.controls.commonCost.controls.length -1
    })

    let target = 'form.expenses.common.commonCost'
    window.setTimeout(() => {
      const element:HTMLElement = document.getElementById(`${target}.${focusObj}.name`) || document.getElementById(`${target}.${focusObj}.value`)
      element.focus();
    }, 0);
  }

  removeCommonExpense(index: number): void {
    const target = 'form.expenses.common.commonCost'

    this.appStore.dispatch(
      new RemoveArrayControlAction(
        target,
        index
      ));

    document.getElementById(`${target}.addMore`).focus()
  }


  advanceFormState(): void {
    const vm = this;
    // increment store
    if (this.canAdvance) {
      if (vm.ValidatedToPage <= 2) vm.appStore.dispatch(incrementValidatedToPage());
      vm.appStore.dispatch(setActivePage({ activePage: 3 }));
    }
  }

  setupErrorWatchers(vm): void {
    vm.canAdvance = 1;
  }

  incrementValidatedToPage() {
    this.appStore.dispatch(incrementValidatedToPage());
  }

  setActivePage(newIndex: number) {
    const vm = this;
    const isInBounds = (newIndex <= vm.ValidatedToPage);
    if (isInBounds) {
      const activePage: number = newIndex;
      vm.appStore.dispatch(setActivePage({ activePage }));
    }
  }

  formatCurrency(value: number): string {
    const locale =  this.activeLang === 'fr' ? 'fr' : 'en';
    let formattedCurrency = formatCurrency( value, locale, getCurrencySymbol('CAD', 'narrow'),'1.2-2');

    //Remove decimal when user enters a whole number
    formattedCurrency = locale === 'fr' ? formattedCurrency.replace(',00','') : formattedCurrency.replace('.00','')

    //Replace narrow no-break space with no-break space
    formattedCurrency = formattedCurrency.replace(" ", " ")
    return formattedCurrency
}

  ngOnInit(): void {
    const vm = this;
    this.setupErrorWatchers(vm);

    // This is a skippable page
    vm.canAdvance = 1;
  }

}
