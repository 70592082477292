<div class="container">
  <div class="row justify-content-center h-100 hide-for-print">
    <div class="col col-12 col-sm-7 col-md-8">
      <div *ngFor="let page of (global$ | async).pageOrder; let i = index" class="row form-placeholder mx-auto py-3"
        [id]="page" [ngClass]="{'d-none': i > ValidatedToPage}">
        <div class="col">
          <div class="row">
            <div class="col">
              <ndc-dynamic [ndcDynamicComponent]="formPages[page + 'Component']">
              </ndc-dynamic>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div [ngClass]="ValidatedToPage < 4 ? 'hide-on-mobile' : ''" class="col col-12 col-sm-5 col-md-4 pl-md-0 pl-lg-3
                  justify-content-center p-relative sticky-chart-container d-block">
      <div class="sticky-chart px-3 w-100">
        <app-finances-chart class="position-relative w-100"></app-finances-chart>
      </div>
    </div>
  </div>

  <div class="col hide-for-screen">
    <div class="col col-12 col-sm-7 col-md-8">
      <div *ngFor="let page of (global$ | async).pageOrder; let i = index" class="row form-placeholder" [id]="page"
        [ngClass]="{'d-none': i > ValidatedToPage}">
        <div class="col">
          <div class="row">
            <div class="col">
              <ndc-dynamic [ndcDynamicComponent]="formPages[page + 'Component']">
              </ndc-dynamic>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container d-flex justify-content-center">
      <div class="col col-12 col-sm-5 col-md-4 pl-md-0 pl-lg-3
                  justify-content-center p-relative sticky-chart-container d-block">
        <div class="sticky-chart px-3 w-100">
          <app-finances-chart class="position-relative w-100"></app-finances-chart>
        </div>
      </div>
    </div>
  </div>

  <div class="row result-header d-flex align-items-end flex-row-reverse d-print-none" *ngIf="ValidatedToPage >= 4">
    <span>
      <div class="row result-header d-flex align-items-end d-print-none" *ngIf="ValidatedToPage >= 4">
        <a role="button" (click)="printPage()" href="javascript:;">
          <span class="td-icon icon-regular td-icon-printer" aria-hidden="true"></span>
          {{ 'form.btn.print' | transloco }}
        </a>
        <a role="button" data-analytics-click="tdct:p:student:budget:calculator:start:over" onclick="location.reload()" href="javascript:;">
          <span class="td-icon icon-regular td-icon-startOver" aria-hidden="true"></span>
          {{ 'form.btn.clear' | transloco }}
        </a>
      </div>
    </span>
  </div>
</div>