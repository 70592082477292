import { Component, Input, OnInit, isDevMode } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { LanguageService } from '../../service/language.service'

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit {

  @Input() wide: boolean;
  availableLangs: string[] | { id: string, label: string }[];
  activeLang: string;
  ariaExpanded: boolean;
  langUrls: {
    en: string;
    fr: string;
    zh: string;
    zt: string;
  };

  constructor(
    private translocoService: TranslocoService,
    private router: Router,
    private languageService: LanguageService
  ) {
    this.langUrls = {
      en: '/ca/en/personal-banking/student-budget-calculator/#/',
      fr: '/ca/fr/services-bancaires-personnels/calculateur-de-budget-etudiant/#/',
      zh: 'https://zh.td.com/ca/en/personal-banking/student-budget-calculator/#/',
      zt: 'https://zt.td.com/ca/en/personal-banking/student-budget-calculator/#/'
    }
    this.ariaExpanded = false;
  }

  ngOnInit(): void {
    const vm = this

    vm.activeLang = vm.languageService.getLang()
    vm.translocoService.setActiveLang(vm.activeLang)
    vm.availableLangs = vm.translocoService.getAvailableLangs()
  }

  getLangDescription(lang: string): string {
    switch (lang) {
      case 'en': return 'English';
      case 'fr': return 'Français';
      case 'zh': return 'Simplified Chinese';
      case 'zt': return 'Traditional Chinese';
      default: return '';
    }
  }

  getLangDescriptionSr(lang: string): string {
    switch (lang) {
      case 'en': return 'Select Language: English';
      case 'fr': return 'Select Language: Français';
      case 'zh': return 'Select language: Simplified Chinese';
      case 'zt': return 'Select language: Traditional Chinese';
      default: return '';
    }
  }

  getLangSr(lang: string): string {
    const vm = this;

    switch (lang) {
      case 'en': return vm.activeLang === 'en' ? 'English Link' : 'Lien anglais';
      case 'fr': return vm.activeLang === 'en' ? 'Français Link' : 'Lien français'
      case 'zh': return 'Simplified Chinese';
      case 'zt': return 'Traditional Chinese';
      default: return '';
    }
  }

  changeLang(lang: string) {
    const vm = this;

    if (isDevMode()) {
      vm.translocoService.setActiveLang(lang)
      vm.activeLang = vm.translocoService.getActiveLang()
      vm.router.navigateByUrl( `/${lang}/?lang=${lang}/`)
    } else {
      // Is prod build.
      // Go to Different TD URL.
      window.location.href = vm.langUrls[lang]
    }
  }
}
