<div [ngClass]="wide ? 'language-switcher-container-wide text-black' : ''" class="language-switcher-container text-white">
  <div [ngClass]="wide ? 'd-block' : ''" class="btn-group" dropdown [attr.aria-expanded]="ariaExpanded" aria-haspopup="true">
    <button
      [ngClass]="wide ? 'toggle-btn-wide pr-4' : ''"
      id="button-language-switcher"
      dropdownToggle
      type="button"
      class="btn btn-secondary dropdown-toggle d-flex align-items-center"
      aria-controls="dropdown-basic"
      aria-labelledby="lang-sr-active"
      (click)="ariaExpanded = !ariaExpanded"
    >
      <span *ngIf="activeLang === 'en' || activeLang === 'fr'">
        {{ wide ? getLangDescription(activeLang) : activeLang }}
      </span>
      <span *ngIf="activeLang === 'zh'">
        简
      </span>
      <span *ngIf="activeLang === 'zt'">
        繁
      </span>
      <span class="caret ml-auto"></span>
      <p id="lang-sr-active" class="sr-only">
        {{ getLangDescriptionSr(activeLang) }}
      </p>
    </button>
    <ul
      [ngClass]="wide ? 'menu-left' : ''"
      id="dropdown-language-switcher" *dropdownMenu
      class="dropdown-menu dropdown-menu-right" role="menu"
      aria-labelledby="button-basic"
    >
      <li (click)="changeLang('en')" role="menuitem">
        <button
          (click)="changeLang('en')"
          class="dropdown-item"
          aria-labelledby="lang-sr-en"
          tabindex="0"
          role="link"
        >
          English
        </button>
        <p id="lang-sr-en" class="sr-only">
          {{ getLangSr('en') }}
        </p>
      </li>
      <li (click)="changeLang('fr')" role="menuitem">
        <button
          (click)="changeLang('fr')"
          class="dropdown-item"
          aria-labelledby="lang-sr-fr"
          tabindex="0"
          role="link"
        >
          Français
        </button>
        <p id="lang-sr-fr" class="sr-only">
          {{ getLangSr('fr') }}
        </p>
      </li>
      <li (click)="changeLang('zh')" role="menuitem">
        <button
          (click)="changeLang('zh')"
          class="dropdown-item"
          aria-labelledby="lang-sr-zh"
          tabindex="0"
          role="link"
        >
          简体中文
        </button>
        <p id="lang-sr-zh" class="sr-only">
          {{ getLangSr('zh') }}
        </p>
      </li>
      <li (click)="changeLang('zt')" role="menuitem">
        <button
          (click)="changeLang('zt')"
          class="dropdown-item"
          aria-labelledby="lang-sr-zt"
          tabindex="0"
          role="link"
        >
          繁體中文
        </button>
        <p id="lang-sr-zt" class="sr-only">
          {{ getLangSr('zt') }}
        </p>
      </li>
    </ul>
  </div>
</div>
