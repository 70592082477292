
<footer role="contentinfo" class="td-padding-vert-0" *transloco="let t; read: 'footer'">
  <div class="td-row">
    <div class="td-footer-content td-col-xs-12 td-bg-colour-6 pb-5">
      <div class="td-container">
        <p class="td-footer-heading td-copy-white td-copy-align-centre">
          {{ t('needToTalk') }}
        </p>
        <p class="td-footer-subtext td-copy-white td-copy-align-centre">
          {{ t('bankingSpecialists') }}
        </p>
        <p class="td-footer-heading td-footer-phone td-copy-white td-copy-align-centre">
          <a [attr.href]="'tel:+'+ t('phone')" rel="noopener noreferrer"  alt="Phone number" target="_self" class="td-footer-phone d-md-none">
            ({{ t('phone') }})
          </a>
          <span class="d-none d-md-inline">
            ({{ t('phone') }})
          </span>
        </p>
        <div class="td-footer-social td-copy-align-centre pb-0">
          <p class="td-footer-social-heading">
            {{ t('connectWithTD') }}
          </p>
          <ul>
            <li>
              <a class="td-link-nounderline" aria-describedby="icon1Description" href="https://twitter.com/td_canada"
                target="_blank" rel="noopener">
                <div class="td-icon-wrapper td-interactive-icon td-background-darkgreen">
                  <span aria-hidden="true" class="td-icon td-icon-twitter"></span>
                  <span class="td-forscreenreader" id="icon1Description">Twitter</span>
                </div>
              </a>
            </li>
            <li>
              <a class="td-link-nounderline" aria-describedby="icon2Description"
                href="https://facebook.com/tdbankgroup/" target="_blank" rel="noopener">
                <div class="td-icon-wrapper td-interactive-icon td-background-darkgreen">
                  <span aria-hidden="true" class="td-icon td-icon-facebook"></span>
                  <span class="td-forscreenreader" id="icon2Description">Facebook</span>
                </div>
              </a>
            </li>
            <li>
              <a class="td-link-nounderline" aria-describedby="icon3Description"
                href="https://www.instagram.com/TD_Canada/" target="_blank" rel="noopener">
                <div class="td-icon-wrapper td-interactive-icon td-background-darkgreen">
                  <span aria-hidden="true" class="td-icon td-icon-instagram"></span>
                  <span class="td-forscreenreader" id="icon3Description">Instagram</span>
                </div>
              </a>
            </li>
            <li>
              <a class="td-link-nounderline" aria-describedby="icon4Description" href="https://www.youtube.com/tdcanada"
                target="_blank" rel="noopener">
                <div class="td-icon-wrapper td-interactive-icon td-background-darkgreen">
                  <span aria-hidden="true" class="td-icon td-icon-youtube"></span>
                  <span class="td-forscreenreader" id="icon4Description">YouTube</span>
                </div>
              </a>
            </li>
            <li>
              <a class="td-link-nounderline" aria-describedby="icon5Description"
                href="https://www.linkedin.com/company/td" target="_blank" rel="noopener">
                <div class="td-icon-wrapper td-interactive-icon td-background-darkgreen">
                  <span aria-hidden="true" class="td-icon td-icon-linkedin"></span>
                  <span class="td-forscreenreader" id="icon5Description">LinkedIn</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="td-footer-content td-col-xs-12 td-bg-colour-5 pb-5 hide-for-print">
      <div class="td-container">
        <div class="td-footer-links td-copy-align-centre td-copy-white">
          <a class="td-copy-white td-link-nounderline td-copy-standard" target="_blank" rel="noopener"
            href="https://www.td.com/privacy-and-security/privacy-and-security/index.jsp">
            {{ t('privacySecurity') }}
          </a>
          <a class="td-copy-white td-link-nounderline td-copy-standard" target="_blank" rel="noopener"
            href="https://www.td.com/to-our-customers/">
            {{ t('legal') }}
          </a>
          <a class="td-copy-white td-link-nounderline td-copy-standard" target="_blank" rel="noopener"
            href="http://www.tdcanadatrust.com/customer-service/accessibility/accessibility-at-td/index.jsp">
            {{ t('accessibility') }}
          </a>
          <a class="td-copy-white td-link-nounderline td-copy-standard" target="_blank" rel="noopener"
            href="https://www.td.com/about-tdbfg/our-business/index.jsp">
            {{ t('aboutUs') }}
          </a>
          <a class="td-copy-white td-link-nounderline td-copy-standard" target="_blank" rel="noopener"
            href="https://jobs.td.com/en-CA/">
            {{ t('hiring') }}
          </a>
          <a class="td-copy-white td-link-nounderline td-copy-standard" target="_blank" rel="noopener"
            href="https://www.td.com/ca/en/personal-banking/manage-content-preferences/">
            {{ t('adChoice') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>