import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss'],
})
export class LegalComponent implements OnInit {
  modalRef: BsModalRef;
  constructor(private modalService: BsModalService) {}

  openModal(template: TemplateRef<any>) {
    this.modalService.onShown.subscribe(() => {
      window.setTimeout(() => {
        document.getElementById('sources-close').focus();
        document
          .getElementsByTagName('app-root')[0]
          .setAttribute('aria-hidden', 'true');
      }, 0);
    });

    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg',
      ariaDescribedby: 'sources-description',
      ariaLabelledBy: 'sources-title',
      focus: true,
    });

    this.modalService.onHide.subscribe(() => {
      document
        .getElementsByTagName('app-root')[0]
        .removeAttribute('aria-hidden');
    });
  }
  ngOnInit(): void {}
}
