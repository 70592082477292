<div class="chart-container w-100">
    <div class="costs-container row mx-0 flex-nowrap">
        <div id="barchart-container"
            class="barchart-container col com-md-1 rounded d-flex flex-column-reverse p-0"
            aria-hidden="true"
        >

            <div class="chartsegment row" *ngIf="schoolSum > 0"
                [ngStyle]="{'height': ((schoolSum * contributionInflation / maxSize ) * 100) + '%'}"
                aria-hidden="true"
            >
                <div class="col col-12" aria-hidden="true">
                    &nbsp;
                </div>
            </div>

            <div class="chartsegment row" *ngIf="housingSum > 0"
                [ngStyle]="{'height': ((housingSum * contributionInflation / maxSize ) * 100) + '%'}"
                aria-hidden="true"
            >
                <div class="col col-12" aria-hidden="true">
                    &nbsp;
                </div>
            </div>

            <div class="chartsegment row" *ngIf="grocerySum > 0"
                [ngStyle]="{'height': ((grocerySum * contributionInflation / maxSize ) * 100) + '%'}"
                aria-hidden="true"
            >
                <div class="col col-12" aria-hidden="true">
                    &nbsp;
                </div>
            </div>

            <div class="chartsegment row" *ngIf="entertainmentSum > 0"
                [ngStyle]="{'height': ((entertainmentSum * contributionInflation / maxSize ) * 100) + '%'}"
                aria-hidden="true"
            >
                <div class="col col-12" aria-hidden="true">
                    &nbsp;
                </div>
            </div>

            <div class="chartsegment row" *ngIf="otherCostsSum > 0"
                [ngStyle]="{'height': ((otherCostsSum * contributionInflation / maxSize ) * 100) + '%'}"
                aria-hidden="true"
            >
                <div class="col col-12" aria-hidden="true">
                    &nbsp;
                </div>
            </div>

        </div>
        <div class="label-container col col-5 rounded d-flex flex-column-reverse">

            <div class="chartsegment row" *ngIf="schoolSum > 0"
                [ngStyle]="{'height': ((schoolSum * contributionInflation / maxSize ) * 100) + '%'}">
                <div class="col col-12">
                    <span class="h3">{{formatCurrency(schoolSum)}}</span> <br>
                    <span>{{ 'chart.school' | transloco }}</span>
                </div>
            </div>

            <div class="chartsegment row" *ngIf="housingSum > 0"
                [ngStyle]="{'height': ((housingSum * contributionInflation / maxSize ) * 100) + '%'}">
                <div class="col col-12">
                    <span class="h3"> {{ formatCurrency(housingSum) }}</span> <br>
                    <span>{{ 'chart.housing' | transloco }}</span>
                </div>
            </div>

            <div class="chartsegment row" *ngIf="grocerySum > 0"
                [ngStyle]="{'height': ((grocerySum * contributionInflation / maxSize ) * 100) + '%'}">
                <div class="col col-12">
                    <span class="h3"> {{ formatCurrency(grocerySum) }}</span> <br>
                    <span>{{ 'chart.grocery' | transloco }}</span>
                </div>
            </div>

            <div class="chartsegment row" *ngIf="entertainmentSum > 0"
                [ngStyle]="{'height': ((entertainmentSum * contributionInflation / maxSize ) * 100) + '%'}">
                <div class="col col-12">
                    <span class="h3"> {{ formatCurrency(entertainmentSum) }}</span> <br>
                    <span>{{ 'chart.entertainment' | transloco }}</span>
                </div>
            </div>

            <div class="chartsegment row" *ngIf="otherCostsSum > 0"
                [ngStyle]="{'height': ((otherCostsSum * contributionInflation / maxSize ) * 100) + '%'}">
                <div class="col col-12">
                    <span class="h3"> {{ formatCurrency(otherCostsSum) }}</span> <br>
                    <span>{{ 'chart.costsOther' | transloco }}</span>
                </div>
            </div>

        </div>
        <div
            class="barchart-container col com-md-1 rounded d-flex flex-column-reverse p-0"
            aria-hidden="true"
        >

            <div class="chartsegment row" *ngIf="scholarshipsSum > 0"
                [ngStyle]="{'height': ((scholarshipsSum * costInflation / maxSize ) * 100) + '%'}"
                aria-hidden="true"
            >
                <div class="col col-12" aria-hidden="true">
                    &nbsp;
                </div>
            </div>

            <div class="chartsegment row" *ngIf="grantsSum > 0"
                [ngStyle]="{'height': ((grantsSum * costInflation / maxSize ) * 100) + '%'}"
                aria-hidden="true"
            >
                <div class="col col-12" aria-hidden="true">
                    &nbsp;
                </div>
            </div>

            <div class="chartsegment row" *ngIf="otherContributionsSum > 0"
                [ngStyle]="{'height': ((otherContributionsSum * costInflation / maxSize ) * 100) + '%'}"
                aria-hidden="true"
            >
                <div class="col col-12" aria-hidden="true">
                    &nbsp;
                </div>
            </div>

            <div class="chartsegment row" *ngIf="savingsSum > 0"
                [ngStyle]="{'height': ((savingsSum * costInflation / maxSize ) * 100) + '%'}"
                aria-hidden="true"
            >
                <div class="col col-12" aria-hidden="true">
                    &nbsp;
                </div>
            </div>

            <div class="chartsegment row" *ngIf="incomeSum > 0"
                [ngStyle]="{'height': ((incomeSum * costInflation / maxSize ) * 100) + '%'}"
                aria-hidden="true"
            >
                <div class="col col-12" aria-hidden="true">
                    &nbsp;
                </div>
            </div>
        </div>
        <div class="label-container col col-5 rounded d-flex flex-column-reverse">

            <div class="chartsegment row" *ngIf="scholarshipsSum > 0"
                [ngStyle]="{'height': ((scholarshipsSum * costInflation / maxSize ) * 100) + '%'}">
                <div class="col col-12">
                    <span class="h3"> {{ formatCurrency(scholarshipsSum) }}</span> <br>
                    <span>{{ 'chart.scholarships' | transloco }}</span>
                </div>
            </div>

            <div class="chartsegment row" *ngIf="grantsSum > 0"
                [ngStyle]="{'height': ((grantsSum * costInflation / maxSize ) * 100) + '%'}">
                <div class="col col-12">
                    <span class="h3"> {{ formatCurrency(grantsSum) }}</span> <br>
                    <span>{{ 'chart.grants' | transloco }}</span>
                </div>
            </div>

            <div class="chartsegment row" *ngIf="otherContributionsSum > 0"
                [ngStyle]="{'height': ((otherContributionsSum * costInflation / maxSize ) * 100) + '%'}">
                <div class="col col-12">
                    <span class="h3"> {{ formatCurrency(otherContributionsSum) }}</span> <br>
                    <span>{{ 'chart.otherContributions' | transloco }}</span>
                </div>
            </div>

            <div class="chartsegment row" *ngIf="savingsSum > 0"
                [ngStyle]="{'height': ((savingsSum * costInflation / maxSize ) * 100) + '%'}">
                <div class="col col-12">
                    <span class="h3"> {{ formatCurrency(savingsSum) }}</span> <br>
                    <span>{{ 'chart.savings' | transloco }}</span>
                </div>
            </div>

            <div class="chartsegment row" *ngIf="incomeSum > 0"
                [ngStyle]="{'height': ((incomeSum * costInflation / maxSize ) * 100) + '%'}">
                <div class="col col-12">
                    <span class="h3"> {{ formatCurrency(incomeSum) }}</span> <br>
                    <span>{{ 'chart.income' | transloco }}</span>
                </div>
            </div>

        </div>

    </div>
    <div class="row mt-3 mx-0">
        <div class="summary-container col px-0">
            <p class="h3 d-block mb-0">
                <span *ngIf="totalCost > 0">{{ formatCurrency(totalCost) }}</span>
                <span *ngIf="totalCost === 0">{{0.00 | currency: "CAD":"symbol-narrow":"1.2-2": activeLang === 'fr' ? 'fr' : 'en'}}</span>
            </p>
            <p class="summary-text">
                {{ 'chart.totalCost' | transloco }}
            </p>
        </div>
        <div class="summary-container col px-0">
            <p class="h3 d-block mb-0">
                <span *ngIf="totalContributions > 0">{{ formatCurrency(totalContributions) }}</span>
                <span *ngIf="totalContributions === 0">{{0.00 | currency: "CAD":"symbol-narrow":"1.2-2": activeLang === 'fr' ? 'fr' : 'en'}}</span>
            </p>
            <p class="summary-text">
                {{ 'chart.totalContribution' | transloco }}
            </p>
        </div>
    </div>
</div>
