import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CostsComponent, ConfigComponent, FormContainerComponent } from './components';

const routes: Routes = [
  { path: 'form', component: FormContainerComponent },
  { path: 'example', component: CostsComponent },
  { path: 'en/', component: FormContainerComponent, },
  { path: 'fr/', component: FormContainerComponent, },
  { path: 'zh/', component: FormContainerComponent, },
  { path: 'zt/', component: FormContainerComponent, },
  { path: '', component: FormContainerComponent },
  { path: '*', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})

export class AppRoutingModule { }