<div class="py-5 hide-for-print" id="housing">
  <div class="w-100">
    <div class="container-fluid px-0">
      <div class="row">
        <!-- Title Offset -->
        <div class="col-12 pl-lg-5 offset-sm-0 offset-md-5 text-center">
          <h3 [scrollTop]="0">{{ 'form.costs.housing.title' | transloco }}</h3>
        </div>
      </div>
      <label class="mt-5">
        {{ 'form.costs.housing.desc' | transloco }}
      </label>


      <form [ngrxFormState]="housingForm$ | async" class="mb-5 housing-form-container">

        <div class="radio-bar">

          <input type="radio" id="housing-choices-home" 
            [ngrxFormControlState]="(housingForm$ | async).controls.choice" [value]="housingChoices.home"
            [attr.aria-selected]="housingChoices.home == (housingForm$ | async).controls.choice.value"
            [attr.aria-label]="'form.costs.housing.home.title' | transloco "
          />
          <label for="housing-choices-home"
                 aria-labelledby="housing-choices-home-sr"
                 class="btn"
                 [innerHTML]="'form.costs.housing.home.title' | transloco ">
          </label>

          <input type="radio" id="housing-choices-residence"
            [ngrxFormControlState]="(housingForm$ | async).controls.choice" [value]="housingChoices.residence"
            [attr.aria-selected]="housingChoices.residence == (housingForm$ | async).controls.choice.value"
            [attr.aria-label]="'form.costs.housing.residence.title' | transloco "
          />
          <label for="housing-choices-residence"
                 id="housing-choices-residence-sr"
                 class="btn"
                 [innerHTML]="'form.costs.housing.residence.title' | transloco ">
          </label>

          <input type="radio" id="housing-choices-renting"
            [ngrxFormControlState]="(housingForm$ | async).controls.choice" [value]="housingChoices.renting"
            [attr.aria-selected]="housingChoices.renting == (housingForm$ | async).controls.choice.value"
            [attr.aria-label]="'form.costs.housing.renting.title' | transloco"
          />
          <label for="housing-choices-renting"
                 id="housing-choices-renting-sr"
                 class="btn"
                 [innerHTML]="'form.costs.housing.renting.title' | transloco">
          </label>

        </div>

        <div *ngIf="housingChoices.home === housingChoice" class="my-5">
          <app-form-input class="d-block mb-5"
            [formControlState]="(housingForm$ | async).controls.home.controls.additionalExpenses" [isCurrency]="true"
            translationScope="form.costs.housing.home.additionalExpenses"></app-form-input>
        </div>

        <div *ngIf="housingChoices.residence === housingChoice" class="my-5">
          <app-form-input class="d-block mb-5"
            [formControlState]="(housingForm$ | async).controls.residence.controls.cost" [isCurrency]="true"
            translationScope="form.costs.housing.residence.cost"></app-form-input>
          <app-form-input class="d-block mb-5"
            [formControlState]="(housingForm$ | async).controls.residence.controls.mealPlan" [isCurrency]="true"
            translationScope="form.costs.housing.residence.mealPlan"></app-form-input>
          <app-form-input [formControlState]="(housingForm$ | async).controls.residence.controls.additionalExpenses"
            [isCurrency]="true" translationScope="form.costs.housing.residence.additionalExpenses"></app-form-input>
        </div>

        <div *ngIf="housingChoices.renting === housingChoice" class="my-5">
          <app-form-input class="d-block mb-5"
            [formControlState]="(housingForm$ | async).controls.renting.controls.monthlyCost" [isCurrency]="true"
            translationScope="form.costs.housing.renting.monthlyCost"></app-form-input>
          <app-form-input class="d-block mb-5"
            [formControlState]="(housingForm$ | async).controls.renting.controls.additionalExpenses" [isCurrency]="true"
            translationScope="form.costs.housing.renting.additionalExpenses"></app-form-input>

        </div>
      </form>
      <div class="row">
        <div class="col-12 col-sm-8 pl-lg-5 offset-sm-0 offset-md-7 text-center next-btn-wrapper mt-5">
          <button class="btn btn-block td-btn-primary-light" class="btn btn-block td-btn-primary-light"
            [disabled]="!canAdvance" (click)="advanceFormState()"
            data-analytics-click="tdct:p:student:budget:calculator:next:step">
            {{ 'form.btn.next' | transloco }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>