import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { NgrxFormsModule } from 'ngrx-forms';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DynamicModule } from 'ng-dynamic-component';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { RootComponent, CostsComponent, ConfigComponent } from './components';
import { rootReducer, initialState, InvalidFieldsSelector, SumCostsSelector } from './store';
import { FinancesChartComponent } from './components/finances-chart/finances-chart.component';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormContainerComponent } from './components';
import { SchoolComponent } from './components/form-pages/school/school.component';
import { HousingComponent } from './components/form-pages/housing/housing.component';
import { ExpensesComponent } from './components/form-pages/expenses/expenses.component';
import { ContributionsComponent } from './components/form-pages/contributions/contributions.component';
import { ResultsComponent } from './components/form-pages/results/results.component';
import { DynamicInputFormComponent } from './components/dynamic-input-form/dynamic-input-form.component';
import { FooterComponent } from './components/footer/footer.component';
import { SuggestionComponent } from './components/suggestion/suggestion.component';

import { TopNavComponent } from './components/top-nav/top-nav.component';
import { TopProgressComponent } from './components/top-progress/top-progress.component';
import { DrawerMenuComponent } from './components/drawer-menu/drawer-menu.component';
import { LegalComponent } from './legal/legal.component';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeZt from '@angular/common/locales/zh';
import localeZh from '@angular/common/locales/zh';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeZt, 'zt');
registerLocaleData(localeZh, 'zh');

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgrxFormsModule,
        StoreModule.forRoot(rootReducer, { initialState }),
        StoreDevtoolsModule.instrument(),
        HttpClientModule,
        TranslocoRootModule,
        BrowserAnimationsModule,
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        FormsModule,
        DynamicModule,
        NgxMaskModule.forRoot(),
        ModalModule.forRoot(),
        ReactiveFormsModule,
    ],
    declarations: [
        RootComponent,
        CostsComponent,
        ConfigComponent,
        FinancesChartComponent,
        LanguageSwitcherComponent,
        FormInputComponent,
        FormContainerComponent,
        TopNavComponent,
        TopProgressComponent,
        SchoolComponent,
        HousingComponent,
        ExpensesComponent,
        ContributionsComponent,
        ResultsComponent,
        DynamicInputFormComponent,
        FooterComponent,
        SuggestionComponent,
        DrawerMenuComponent,
        LegalComponent
    ],
    providers: [InvalidFieldsSelector, SumCostsSelector],
    bootstrap: [RootComponent],
})

export class AppModule {
}
