<!-- Modal --> 
<ng-template #sources>
  <div class="modal-header">
    <h3 class="pull-left source-modal-header" id="sources-title">{{ 'legal.modal_header' | transloco }}</h3>
    <button type="button" id="sources-close" class="close pull-right" [attr.aria-label]="'legal.modal_close' | transloco" (click)="modalRef.hide();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" id="sources-description" [innerHTML]='"legal.modal_body" | transloco '></div>
</ng-template>

<div role="contentinfo" class="legal-container" *transloco="let t; read: 'footer'">
  <div class="td-row">
    <div class="col offset-2 col-8 offset-md-3 col-md-6 mt-5 mb-5">
      <p class="td-legal-copy">
        {{ 'legal.disclaimer' | transloco }}
        <!-- Button trigger modal -->
        <a href="javascript:;"
          (click)="openModal(sources);"
          role="button"
          [attr.aria-label]='"legal.clickherearia" | transloco '>
          {{ 'legal.clickhere' | transloco }}
        </a>.
      </p>
    </div>
  </div>
</div>