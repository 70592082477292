<aside [ngClass]="open ? 'drawer-menu-open' : ''" class="drawer-menu">
  <div [ngClass]="!open ? 'menu-overlay-closed' : ''" class="menu-overlay" (click)="onClose()"></div>
  <div [ngClass]="!open ? 'menu-closed' : ''" class="menu pt-3 pl-4">
    <div class="container">
      <div class="row mr-0 pr-2">
        <div class="navbar-brand td-logo ml-0 mr-auto">
          <img src="assets/img/td-logo.png" alt="TD Canada Trust" aria-hidden="true">
        </div>
        <div class="d-flex align-items-center">
          <button
            id="mobile-nav-close"
            class="close-btn btn btn-text"
            (click)="onClose()"
            type="button"
          >
            <span class="sr-only" aria-hidden="">Close Menu</span>
            <span class="td-icon td-icon-close" aria-hidden="true"></span>
          </button>
        </div>
      </div>
      <div class="row mt-5 ml-1">
        <app-language-switcher class="w-100" [wide]="true"></app-language-switcher>
      </div>
    </div>
  </div>
</aside>
