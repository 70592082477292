<div *transloco="let t; read: translationScope">
  <label *ngIf="(!(t('title') === '') || !(t('desc') === '')) && !isDynamic"  [id]="t('title') + 'title'" [ngClass]="{ 'sr-only': t('title') === '' && !isDynamic }">
    {{ t('title') || t('desc') }}&nbsp;
  </label>
  <!-- class="td-icon td-icon-colour-primary icon-regular td-icon-information" -->
  <button *ngIf="!(t('info') === '')"
    class="td-icon td-icon-colour-primary icon-regular td-icon-information"
    tooltip="{{ t('info') }}"
    placement="right"
    [attr.aria-labelledby]="t('title') + 'title'"
    [attr.aria-label]="t('info')"
    aria-hidden="false"
    tabindex="0"
    [attr.aria-describedby]="translationScope + '.info'"
  ></button>
  <p *ngIf="!(t('info') === '')" [id]="translationScope + '.info'" class="sr-only">{{ t('info')}}</p>
  <p *ngIf="!(t('desc') === '')">
    <small class="text-muted helper text">
      {{ t('desc') }}
    </small>
  </p>
  <div class="form-input-wrapper form-group mb-0" [ngClass]="activeLang === 'fr' ? 'form-left' : 'form-right'">
    <div [class]="isCurrency ? 'input-group' : ''">
      <div *ngIf="isCurrency" [class]="activeLang === 'fr' ? 'input-group-append'  : 'input-group-prepend' " >
        <span [ngClass]="{'deactivated-text': !formControlState.value, 'with-suffix': activeLang === 'fr' && isCurrency && hasSuffix}" class="input-group-text bg-white" >$</span>
      </div>
      <input  *ngIf="isCurrency" type="text" class="form-control"
            [mask]="'separator.2'"
            [separatorLimit]="'99999'"
            [thousandSeparator]="activeLang === 'fr' ? ' ' : ','"
            [decimalMarker]="activeLang === 'fr' ? ',' : '.'"
            [id]="formControlState.id"
            [ngrxFormControlState]="formControlState"
            [placeholder]="t('placeholder') || ''"
            [ngClass]="{'input-with-suffix': hasSuffix}"
            tabindex="0"
            [attr.aria-labelledby]="isDynamic && !inputAriaLabel() ? formControlState.id + '-label' : null"
            [attr.aria-label]="inputAriaLabel()"
        
          >
      <input *ngIf="!isCurrency" type="text" class="form-control"
          [id]="formControlState.id"
          [ngrxFormControlState]="formControlState"
          [placeholder]="t('placeholder') || ''"
          [ngClass]="{'input-with-suffix': hasSuffix}"
          tabindex="0"
          [attr.aria-label]= "formControlState.value ? inputAriaLabel() : ' ' "
        >
      <div *ngIf="hasSuffix" class="input-group-append">
        <span [ngClass]="{'deactivated-text': !formControlState.value}" class="input-group-text bg-white pl-1">
          {{t('suffix')}}
        </span>
      </div>
    </div>
    <div class="td-error error-container" *ngIf="toArray(formControlState.errors).length > 0">
      <div
        *ngFor="let errorItem of toArray(formControlState.errors)"
        class="error-item"
        aria-hidden="false"
        role="alert"
        tabindex="0"
      >
        <span class="td-error-symbol" aria-hidden="true"></span>
        <span class="sr-only">Error:</span>
        <span class="error-massage" *transloco="let e; read: 'error'">
          {{
            e(
                errorItem.toString(),
                {
                    user: formControlState.errors[errorItem][0] || '??' | mask: 'separator':',',
                    validSecond: formControlState.errors[errorItem][2] | mask: 'separator': activeLang === "fr"? ' ' : ',',
                    valid: formControlState.errors[errorItem][1] | mask: 'separator': activeLang === "fr"? ' ' : ','
                }
            )
          }}
        </span>
      </div>
    </div>
  </div>
</div>