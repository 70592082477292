import {ValidationFn} from 'ngrx-forms';

/**
 * Validator function for required input
 * @param required Whether the input is required
 */
export function isRequired(required: boolean, minVal?: number, maxVal?: number): ValidationFn<any> {
    // Assertion: !required || (minVal && maxVal)
    return (value: number) => {
        return !(value == null && required) ? null
            : {['withinRange']: [value, minVal, maxVal]};
    };
}

/**
 * Validator function for numeric input with minimum restriction
 * @param minVal Minimum numeric value of the input
 * @param key Translation key for customized message
 */
export function minimum(minVal: number, key?: string): ValidationFn<number> {
    return (value: number) => {
        return value == null || value >= minVal ? null
            : {[key ?? 'minAmount']: [value, minVal]};
    };
}

/**
 * Validator function for numeric input with maximum restriction
 * @param maxVal Maximum numeric value of the input
 * @param key Translation key for customized message
 */
export function maximum(maxVal: number, key?: string): ValidationFn<number> {
    return (value: number) => {
        return value == null || value <= maxVal ? null
            : {[key ?? 'maxAmount']: [value, maxVal]};
    };
}

/**
 * Validator function for numeric input with minimum & maximum restriction
 * @param minVal Minimum numeric value of the input
 * @param maxVal Maximum numeric value of the input
 * @param key Translation key for customized message
 */
export function withinRange(minVal: number, maxVal: number, key?: string): ValidationFn<number> {
    return (value: number) => {
        return value == null || (value >= minVal && value <= maxVal)
            ? null : {[key ?? 'withinRange']: [value, minVal, maxVal]};
    };
}
