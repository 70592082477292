import { Component, OnInit, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import Sticky from 'sticky-js';
import { Global } from '../../models';
import { AppState, setActivePage } from '../../store';
import { scrollTo } from '../../util/scrollTo';

@Component({
  selector: 'app-top-progress',
  templateUrl: './top-progress.component.html',
  styleUrls: ['./top-progress.component.scss'],
})
export class TopProgressComponent implements OnInit {
  private appStore: Store<AppState>;
  global$: Observable<Global>;
  ValidatedToPage: number;
  verticalOffset: number;
  sticky = new Sticky('.sticky-progress', {
    stickyClass: 'is-sticky',
    wrap: true,
  });
  scrollTo: any;
  activePage: number;
  pageOrder$: Observable<string[]>;
  pageOrder: string[];

  onScroll(_) {
    this.verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    this.onScroll($event);
  }

  constructor(private store: Store<AppState>) {
    this.appStore = store;
    this.global$ = store.pipe(select((state) => <Global>state.global));
    this.scrollTo = scrollTo;
    this.pageOrder$ = this.appStore.pipe(
      select((state) => <string[]>state.global.pageOrder)
    );
  }

  setActivePage(newIndex: number) {
    let vm = this;
    let isInBounds = newIndex <= vm.ValidatedToPage;
    if (isInBounds) {
      vm.activePage = newIndex;
      vm.triggerScroll(vm.activePage);
      vm.appStore.dispatch(setActivePage({ activePage: vm.activePage }));
    }
  }

  focusOnSection(section) {
    //Focuses on the first element in a given section
    const focusableElements =
      ' input, button, [href], [tabindex]:not([tabindex="-1"])';
    const modal = document.querySelector(`#${section}`);
    const focusableContentInit = modal.querySelectorAll(focusableElements);

    //Focus on the first element that has aria-selected as true, if there are none focus on the first element in section.
    for (let i = 0; i < focusableContentInit.length; ++i) {
      if (focusableContentInit[i].getAttribute('aria-selected') === 'true') {
        (focusableContentInit[i] as HTMLElement)?.focus();
        return;
      }
    }
    (focusableContentInit[0] as HTMLElement)?.focus();
  }

  triggerScroll(index) {
    if (this.ValidatedToPage >= this.activePage) {
      const section = this.pageOrder[index];
      this.scrollTo(section);

      //The first element in the section must be focused so that when to bar is used to navigate the page, tab order progresses properly.
      this.focusOnSection(section);
    }
  }

  ngOnInit(): void {
    this.onScroll(null);
    let vm = this;

    vm.pageOrder$.subscribe(async (pageOrder) => {
      vm.pageOrder = pageOrder;
    });

    vm.global$.subscribe((globals) => {
      vm.ValidatedToPage = globals.ValidatedToPage;
    });
  }
}
