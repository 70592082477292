<app-drawer-menu [open]="drawerOpen" (closeDrawer)="closeDrawer()" *ngIf="drawerOpen"></app-drawer-menu>
<nav class="navbar header fixed-top td-bg-colour-4 px-0 py-0 d-print-inline-block">
  <div class="container">
    <div class="row py-2 pl-0 pr-0 align-items-center w-100 mx-auto">
      <div class="col-auto col-sm-3 align-items-center pr-0">
        <div class="row mr-0 align-items-center">
          <button
            id="nav-menu-btn"
            class="d-flex d-sm-none btn btn-text"
            (click)="openDrawer()"
            type="button"
            aria-haspopup="true"
            [attr.aria-expanded]="drawerOpen"
          >
            <span class="sr-only" aria-hidden="false">Open Menu</span>
            <span class="td-icon td-icon-hamburger" aria-hidden="true"></span>
        </button>
          <div class="navbar-brand td-logo mr-0">
            <img src="assets/img/td-logo.png" alt="TD Canada Trust"  aria-hidden="true">
          </div>
        </div>
      </div>
      <div class="col col-sm-6 d-flex align-items-center pr-0">
        <h1 id="nav-title" class="h2 text-white px-0 py-0 mb-0 align-middle mx-auto text-nowrap">
          {{ 'page.header.title' | transloco }}
        </h1>
      </div>
      <div class="d-none d-sm-flex col-sm-3 align-items-right pull-right pr-0">
        <app-language-switcher class="ml-auto"></app-language-switcher>
        <span class="bg-white p-1" *ngIf="devMode">
          dev links:
          <a routerLink="/example">example</a>|
          <a routerLink="/form">form</a>
        </span>
      </div>
    </div>
  </div>
</nav>

<!-- <button type="button" class="td-mobile-action-button td-mobile-menu-button" style="display:inline;" aria-haspopup="true" aria-expanded="false">
  <div class="td-mobile-menu-button-icon">
      <span class="td-forscreenreader">Open Menu</span>
      <span class="icon-bar" aria-hidden="true"></span>
      <span class="icon-bar" aria-hidden="true"></span>
      <span class="icon-bar" aria-hidden="true"></span>
  </div>
  <div class="td-logo">
  <img src="/ca/en/personal-banking/system/v1.5/assets/img/header-nav/td-logo.png" srcset="" alt="TD Canada Trust"></div>
</button> -->